<template>
  <div class="container-fluid">
    <div class="page-wrapper">
      <div slot="header" class="page-wrapper-header">
        <div class="page-wrapper-header-left booking">
          <div class="kw-back">
            <h3>{{ $t("PRICING_RULES.PRICES") }}</h3>
          </div>
          <div class="kw-breadcrumb">
            <ul>
              <li>
                <img src="/img/kw-home.svg" alt="icon" />
                <span>{{ $t("APPS.PRICING_MODULE") }}</span>
              </li>
              <li>{{ $t("PRICING_RULES.PRICES") }}</li>
            </ul>
          </div>
        </div>

        <div v-if="globalOrganization?.id" class="page-wrapper-header-right">
          <base-button
            class="kw-button add"
            icon
            size="sm"
            @click="openPricingRuleCreateModal"
            v-if="$currentUserCan($permissions.PERM_CREATE_PRICING_RULES)"
          >
            <span class="btn-inner--icon">
              <i class="far fa-plus"></i>
            </span>
            <span class="btn-inner--text">{{
              $t("PRICING_RULES.ADD_PRICING_RULES")
            }}</span>
          </base-button>
        </div>
      </div>
      <div v-if="globalOrganization?.id">
        <pricing-rule-list-table
          @onViewPricingRule="openPricingRuleViewModal"
          @onEditPricingRule="openPricingRuleEditModal"
          @onDeletePricingRule="deletePricingRule"
          @onAddPricingRule="openPricingRuleCreateModal"
          :key="renderKey * 100"
        />

        <div
          v-if="isViewPricingRuleModalOpened"
          class="resizable-wrapper"
          v-bind:class="[isViewPricingRuleModalOpened ? 'show' : 'hide']"
          :key="renderKey * 200"
        >
          <div
            class="resizable-wrapper-content"
            v-resizable-modal="'SPOT_TYPE_VIEW'"
          >
            <div class="resizable-wrapper-content-header">
              <div class="resizable-wrapper-content-header-left">
                <button @click="closePricingRuleModal">
                  <i class="far fa-arrow-left"></i>
                </button>
                <h1>
                  {{ $t("PRICING_RULES.VIEW_PRICING_RULE") }}
                </h1>
              </div>
              <div class="resizable-wrapper-content-header-right">
                <wrapper-expand-button></wrapper-expand-button>
              </div>
            </div>
            <div class="resizable-wrapper-content-body">
              <view-pricing-rule-component
                v-if="openPricingRule"
                :pricingRuleId="openPricingRule.id"
                @onEditPricingRule="openPricingRuleEditModal"
                @onDeletePricingRule="deletePricingRule"
              />
            </div>
          </div>
        </div>

        <div
          v-if="isEditPricingRuleModalOpened"
          class="resizable-wrapper"
          v-bind:class="[isEditPricingRuleModalOpened ? 'show' : 'hide']"
          :key="renderKey * 300"
        >
          <div
            class="resizable-wrapper-content"
            v-resizable-modal="'SPOT_TYPE'"
          >
            <div class="resizable-wrapper-content-header">
              <div class="resizable-wrapper-content-header-left">
                <button @click="closePricingRuleModal">
                  <i class="far fa-arrow-left"></i>
                </button>
                <h1>
                  {{ $t("PRICING_RULES.EDIT_PRICING_RULE") }}
                </h1>
              </div>
              <div class="resizable-wrapper-content-header-right">
                <wrapper-expand-button></wrapper-expand-button>
              </div>
            </div>
            <div class="resizable-wrapper-content-body">
              <edit-pricing-rule-component
                v-if="openPricingRule"
                :pricingRuleId="openPricingRule.id"
                @onViewPricingRule="openPricingRuleViewModal"
                @onClosePricingRuleModal="closePricingRuleModal"
              />
            </div>
          </div>
        </div>

        <div
          v-if="isAddPricingRuleModalOpened"
          class="resizable-wrapper"
          v-bind:class="[isAddPricingRuleModalOpened ? 'show' : 'hide']"
          :key="renderKey * 400"
        >
          <div
            class="resizable-wrapper-content"
            v-resizable-modal="'SPOT_TYPE'"
          >
            <div class="resizable-wrapper-content-header">
              <div class="resizable-wrapper-content-header-left">
                <button @click="closePricingRuleModal">
                  <i class="far fa-arrow-left"></i>
                </button>
                <h1>
                  {{ $t("PRICING_RULES.ADD_PRICING_RULES") }}
                </h1>
              </div>
              <div class="resizable-wrapper-content-header-right">
                <wrapper-expand-button></wrapper-expand-button>
              </div>
            </div>
            <div class="resizable-wrapper-content-body">
              <add-pricing-rule-component
                @onViewPricingRule="openPricingRuleViewModal"
                @onClosePricingRuleModal="closePricingRuleModal"
              />
            </div>
          </div>
        </div>
      </div>
      <select-an-organization-message v-else />
    </div>
  </div>
</template>
<script>
import swal from "sweetalert2";
import { Button } from "element-ui";
import requestErrorMixin from "@/mixins/request-error-mixin";
import {
  QUERY_ACTIONS_VIEW,
  QUERY_ACTIONS_EDIT,
  QUERY_ACTIONS_ADD,
} from "@/constants/common";
// import NotificationSubscription from "@/components/NotificationSubscription.vue";
import PricingRuleListTable from "./partials/PricingRuleListTable.vue";
import { mapGetters } from "vuex";
import EditPricingRuleComponent from "./components/EditPricingRuleComponent.vue";
import AddPricingRuleComponent from "./components/AddPricingRuleComponent.vue";
import ViewPricingRuleComponent from "./components/ViewPricingRuleComponent.vue";
import WrapperExpandButton from "@/components/WrapperExpandButton";
import SelectAnOrganizationMessage from "@/components/SelectAnOrganizationMessage.vue";

export default {
  layout: "DashboardLayout",

  components: {
    WrapperExpandButton,
    PricingRuleListTable,
    // NotificationSubscription,
    EditPricingRuleComponent,
    AddPricingRuleComponent,
    ViewPricingRuleComponent,
    SelectAnOrganizationMessage,
    [Button.name]: Button,
  },

  mixins: [requestErrorMixin],

  computed: {
    ...mapGetters("organizations", {
      globalOrganization: "globalOrganization",
    }),
    ...mapGetters("organizationOptions", {
      organizationConfig: "organizationConfig",
    }),
  },

  watch: {},

  data() {
    const pricingRuleId = this.$route.query.id;
    const action = this.$route.query.action;
    let isViewPricingRuleModalOpened = false;
    let isEditPricingRuleModalOpened = false;
    let isAddPricingRuleModalOpened = false;
    let openPricingRule = null;
    if (pricingRuleId && action) {
      if (action === QUERY_ACTIONS_VIEW) {
        isViewPricingRuleModalOpened = true;
      } else if (action === QUERY_ACTIONS_EDIT) {
        isEditPricingRuleModalOpened = true;
      }
      openPricingRule = { id: pricingRuleId };
    } else if (action === QUERY_ACTIONS_ADD) {
      isAddPricingRuleModalOpened = true;
    }
    return {
      isViewPricingRuleModalOpened: isViewPricingRuleModalOpened,
      isEditPricingRuleModalOpened: isEditPricingRuleModalOpened,
      isAddPricingRuleModalOpened: isAddPricingRuleModalOpened,
      openPricingRule: openPricingRule,
      renderKey: 1,
    };
  },

  methods: {
    openPricingRuleCreateModal() {
      this.closePricingRuleModal();
      this.isAddPricingRuleModalOpened = true;

      history.pushState(
        {},
        null,
        this.$router.resolve({
          name: "List PricingRules",
          query: { action: QUERY_ACTIONS_ADD },
        }).href
      );
    },
    openPricingRuleViewModal(pricingRule, reRender = false) {
      this.closePricingRuleModal();
      this.openPricingRule = pricingRule;
      this.isViewPricingRuleModalOpened = true;
      if (reRender) {
        this.renderKey++;
      }

      history.pushState(
        {},
        null,
        this.$router.resolve({
          name: "List PricingRules",
          query: { id: this.openPricingRule.id, action: QUERY_ACTIONS_VIEW },
        }).href
      );
    },
    openPricingRuleEditModal(pricingRule) {
      this.closePricingRuleModal();
      this.openPricingRule = pricingRule;
      this.isEditPricingRuleModalOpened = true;

      history.pushState(
        {},
        null,
        this.$router.resolve({
          name: "List PricingRules",
          query: { id: this.openPricingRule.id, action: QUERY_ACTIONS_EDIT },
        }).href
      );
    },
    closePricingRuleModal() {
      this.isAddPricingRuleModalOpened = false;
      this.isViewPricingRuleModalOpened = false;
      this.isEditPricingRuleModalOpened = false;
      this.openPricingRule = null;

      history.pushState(
        {},
        null,
        this.$router.resolve({
          name: "List PricingRules",
          query: {},
        }).href
      );
    },

    async deletePricingRule(pricingRule) {
      const confirmation = await swal.fire({
        text: this.$t("PRICING_RULES.DELETE_THIS_PRICING_RULE"),
        type: "question",
        customClass: {
          popup: "delete-popup",
        },
        buttonsStyling: false,
        showCancelButton: true,
        cancelButtonText: this.$t("COMMON.CANCEL"),
        confirmButtonText: this.$t("COMMON.DELETE_ITEM"),
        confirmButtonClass: "btn btn-primary",
        cancelButtonClass: "btn btn-warning",
      });

      try {
        if (confirmation.value === true) {
          await this.$store.dispatch("pricingRules/destroy", pricingRule.id);
          this.renderKey++;
          this.closePricingRuleModal();
          this.$notify({
            type: "success",
            message: this.$t("PRICING_RULES.PRICING_RULE_DELETED"),
          });
        }
      } catch (error) {
        await this.showRequestError(error);
        this.$notify({
          type: "danger",
          message: this.$t("ERRORS.SOMETHING_WENT_WRONG"),
        });
      }
    },
  },
};
</script>
