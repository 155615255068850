<template>
  <div class="elite-tabs-wrapper-content">
    <h3>{{ pricingRule.name }}</h3>
    <div class="all-infos">
      <div class="all-infos-text mt-3">
<!--        <dl class="row">
          <dt>{{ $t("PRICING_RULES.BUNDLE") }}</dt>
          <dd>Bundle {{ pricingRule.bundle_id }}</dd>
        </dl>-->
        <dl class="row">
          <dt>{{ $t("PRICING_RULES.LODGING_TAX") }}</dt>
          <dd>
            {{ pricingRule.lodging_tax ? $t("COMMON.YES") : $t("COMMON.NO") }}
          </dd>
        </dl>
        <dl class="row">
          <dt>{{ $t("PRICING_RULES.ADULTADDCOST") }}</dt>
          <dd>
            {{ $formatCurrency(pricingRule.adult_add_cost_value) }}
          </dd>
        </dl>
<!--        <dl class="row">
          <dt>{{ $t("PRICING_RULES.CHILDRENADDCOST") }}</dt>
          <dd>
            {{ $formatCurrency(pricingRule.children_add_cost_value) }}
          </dd>
        </dl>-->
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "pricing-rule-view-cost",

  props: ["pricingRule"],

  components: {},

  data() {
    return {};
  },

  computed: {},

  created() {},

  methods: {},

  mounted() {},

  watch: {
    contact(contact) {},
  },
};
</script>
