<template>
  <div class="elite-tabs-wrapper-content">
    <h3>{{ pricingRule.name }}</h3>
    <div class="all-infos">
      <div class="all-infos-text mt-3">
        <dl class="row">
          <dt>{{ $t("PRICING_RULES.SOURCE") }}</dt>
          <dd>
            {{ sources[pricingRule.source_id] }}
          </dd>
        </dl>
        <dl class="row" v-if="pricingRule.maximum_person !== null">
          <dt>{{ $t("PRICING_RULES.CANAL") }}</dt>
          <dd>
            {{ canals[pricingRule.canal_id] }}
          </dd>
        </dl>
        <dl class="row" v-if="pricingRule.minimum_stay !== null">
          <dt>{{ $t("PRICING_RULES.SEGMENT") }}</dt>
          <dd>
            {{ segments[pricingRule.segment_id] }}
          </dd>
        </dl>
<!--        <dl class="row" v-if="pricingRule.maximum_stay !== null">
          <dt>{{ $t("PRICING_RULES.IDENTIFICATION") }}</dt>
          <dd>
            {{ identifications[pricingRule.identification_id] }}
          </dd>
        </dl>-->
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "pricing-rule-view-marketing",

  props: ["pricingRule"],

  components: {},

  data() {
    return {
      sources: {
        1: "Agence 1",
        2: "Agence 2",
      },
      canals: {
        1: "Airbnb",
        2: "Booking",
        3: "TripAdvisor",
      },
      segments: {
        1: "Segment 1",
        2: "Segment 2",
      },
      identifications: {
        1: "Id 1",
        2: "Id 2",
      },
    };
  },

  computed: {},

  created() {},

  methods: {},

  mounted() {},

  watch: {
    contact(contact) {},
  },
};
</script>
