<template>
  <div class="step-content-body">
    <div class="step-content-body-title">
      <div class="step-content-body-title-left">
        <p>{{ $t("PRICING_RULES.MARKETING") }}</p>
      </div>
      <div class="step-content-body-title-right"></div>
    </div>
    <div class="form-group-wrapper">
      <div class="form-wrapper width_1-2">
        <base-input :label="`${$t('PRICING_RULES.SOURCE')}`">
          <el-select
            v-model="sourceValue"
            :placeholder="`${$t('PRICING_RULES.SOURCE')}`"
          >
            <el-option
              v-for="source in sources"
              :key="source.value"
              :label="source.label"
              :value="source.value"
            >
            </el-option>
          </el-select>
        </base-input>
      </div>
      <div class="form-wrapper width_1-2">
        <base-input :label="`${$t('PRICING_RULES.CANAL')}`">
          <el-select
            v-model="canalValue"
            :placeholder="`${$t('PRICING_RULES.CANAL')}`"
          >
            <el-option
              v-for="canal in canals"
              :key="canal.value"
              :label="canal.label"
              :value="canal.value"
            >
            </el-option>
          </el-select>
        </base-input>
      </div>
      <div class="form-wrapper width_1-2">
        <base-input :label="`${$t('PRICING_RULES.SEGMENT')}`">
          <el-select
            v-model="segmentValue"
            :placeholder="`${$t('PRICING_RULES.SEGMENT')}`"
          >
            <el-option
              v-for="segment in segments"
              :key="segment.value"
              :label="segment.label"
              :value="segment.value"
            >
            </el-option>
          </el-select>
        </base-input>
      </div>
<!--      <div class="form-wrapper width_1-2 has-button">
        <base-input :label="`${$t('PRICING_RULES.IDENTIFICATION')}`">
          <el-select
            v-model="identificationValue"
            :placeholder="`${$t('PRICING_RULES.IDENTIFICATION')}`"
          >
            <el-option
              v-for="identification in identifications"
              :key="identification.value"
              :label="identification.label"
              :value="identification.value"
            >
            </el-option>
          </el-select>
        </base-input>
      </div>-->
    </div>
  </div>
</template>
<script>
import { Option, Radio, Select } from "element-ui";
export default {
  name: "marketing-step",

  components: {
    [Select.name]: Select,
    [Option.name]: Option,
    [Radio.name]: Radio,
  },

  props: {
    source: {
      type: Number,
      default: 1,
      description: "source",
    },
    canal: {
      type: Number,
      default: 1,
      description: "canal",
    },
    segment: {
      type: Number,
      default: 1,
      description: "segment",
    },
    identification: {
      type: Number,
      default: 1,
      description: "identification",
    },
  },

  mixins: [],

  data() {
    return {
      sources: [
        // {
        //   value: 1,
        //   label: "Agence 1",
        // },
        // {
        //   value: 2,
        //   label: "Agence 2",
        // },
      ],
      sourceValue: this.source,
      canals: [
        // {
        //   value: 1,
        //   label: "Airbnb",
        // },
        // {
        //   value: 2,
        //   label: "Booking",
        // },
        // {
        //   value: 3,
        //   label: "TripAdvisor",
        // },
      ],
      canalValue: this.canal,
      segments: [
        // {
        //   value: 1,
        //   label: "Segment 1",
        // },
        // {
        //   value: 2,
        //   label: "Segment 2",
        // },
      ],
      segmentValue: this.segment,
      identifications: [
        // {
        //   value: 1,
        //   label: "Id 1",
        // },
        // {
        //   value: 2,
        //   label: "Id 2",
        // },
      ],
      identificationValue: this.identification,
    };
  },

  computed: {},

  methods: {},

  watch: {
    sourceValue(value) {
      this.$emit("update:source", value);
    },
    canalValue(value) {
      this.$emit("update:canal", value);
    },
    segmentValue(value) {
      this.$emit("update:segment", value);
    },
    identificationValue(value) {
      this.$emit("update:identification", value);
    },
  },
};
</script>
